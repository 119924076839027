import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { RunStatus } from "../../interfaces/enums";
import {
  MarketMoveMetaDataSchema,
  MarketMoveRunSchema,
  PreviewDetailsSchema
} from "../../schema/schemas";
import {
  useFormActions,
  useMarketMoveMetaDataMethods,
  useMarketMoveRunMethods,
  usePreviewDetailsMethods,
} from "../../store/formStore";
import useGeneralStore from "../../store/generalStore";
import useMarketMoveStore from "../../store/marketMoveStore";
import { areConfigsIdentical, areStoreListsIdentical, generateDefaultMarketMove } from "../../utils/marketMoveUtils";
import { formatErrorsForTooltip } from "../../utils/textUtils";
import ProductSettingsContainer from "../product/ProductSettingsContainer";
import MarketMoveSettings from "./marketMoveSettings/MarketMoveSettings";



const MarketMoveForm: React.FC = () => {
  const { showPreview, toggleRecap, setShowPreview, listViewSelectedStores } =
    useGeneralStore();
  const {
    setSelectedMarketMoveId,
    setPreviewStale,
    isPreviewStale,
    incrementMMMetaDataSaveReq,
    disableEditing,
    hasInitialLoadCompleted,
    setHasInitialLoadCompleted,
    incrementPreviewDetailsFetchReq,
    setSaveOnRecap,
    saveOnRecap,
  } = useMarketMoveStore();
  const {
    control: marketMoveMetaDataControl,
    reset,
    formState: { isValid: isMetaDataValid, errors: marketMoveMetaDataErrors, isDirty: isMetaDataDirty },
  } = useMarketMoveMetaDataMethods();
  const {
    control: previewDetailsControl,
    formState: {
      dirtyFields: previewDetailsDirtyFields = {},
    },
  } = usePreviewDetailsMethods();
  const { control: marketMoveRunControl } = useMarketMoveRunMethods();
  const { setRunStatus } = useFormActions();
  const currentMarketMove = useWatch<MarketMoveMetaDataSchema>({
    control: marketMoveMetaDataControl,
  });
  const marketMoveRun = useWatch<MarketMoveRunSchema>({
    control: marketMoveRunControl,
  });
  const previewDetails = useWatch<PreviewDetailsSchema>({
    control: previewDetailsControl,
  });

  const previewButtonState = useMemo(() => {
    const guards = [
      {
        name: "No stores in storeList",
        value: !currentMarketMove?.storeList?.length,
      },
      { name: "Editing Disabled", value: disableEditing },
    ];

    const triggers = [
      {
        name: "No preview details have been generated - refresh needed",
        value: !marketMoveRun?.marketMoveRunRecapDetails?.length,
      },
      { name: "The preview is currently hidden", value: !showPreview },
      {
        name: `The product configurations have changed`,
        value: !areConfigsIdentical(
          currentMarketMove?.productConfigList,
          previewDetails?.marketMoveProductConfigs
        ),
      },
      {
        name: `The store list has changed - refresh needed`,
        value: !areStoreListsIdentical(
          currentMarketMove?.storeList,
          previewDetails?.marketMoveStores
        ),
      },
      {
        name: "The store product configurations have changed - refresh needed",
        value: previewDetailsDirtyFields.storeProductConfig,
      },
    ];

    const allGuardsPassed = guards.every((guard) => !guard.value);
    const anyTriggerActive = triggers.some((trigger) => trigger.value);

    const isDisabled = !allGuardsPassed || !anyTriggerActive;
    const tooltipText = [...guards, ...triggers]
      .filter((condition) => condition.value)
      .map((condition) => condition.name)
      .join(" | ");


    return { isDisabled, tooltipText };
  }, [
    currentMarketMove?.storeList,
    currentMarketMove?.productConfigList,
    previewDetails,
    previewDetailsDirtyFields,
    marketMoveRun?.marketMoveRunRecapDetails,
    showPreview,
    disableEditing,
  ]);

  const recapButtonState = useMemo(() => {
    const conditions = [
      {
        name: `Market Move Settings errors:\n${formatErrorsForTooltip(
          marketMoveMetaDataErrors
        )}`,
        value: !isMetaDataValid,
      },
      {
        name: "Product configurations are needed before proceeding to recap",
        value: !currentMarketMove?.productConfigList?.length,
      },
      {
        name: "Preview details are needed before proceeding to recap - please refresh the preview",
        value: !marketMoveRun?.marketMoveRunRecapDetails,
      },
      {
        name: "All recap products are excluded - can not proceed to recap",
        value: marketMoveRun?.marketMoveRunRecapDetails
          ? !marketMoveRun.marketMoveRunRecapDetails.filter(
              (recap) => recap.includeFlag
            ).length
          : false,
      },
      {
        name: "No included stores in storeList - can not proceed to recap",
        value: !currentMarketMove.storeList?.filter(
          (store) => store.includeFlag
        ).length,
      },
      { name: "Preview needs a refresh - please refresh the preview", value: !previewButtonState.isDisabled },
    ];

    const isDisabled =
      conditions.some((condition) => condition.value) && !disableEditing;
    const tooltipText = conditions
      .filter((condition) => condition.value)
      .map((condition) => condition.name)
      .join(" | ");

    return { isDisabled, tooltipText };
  }, [
    marketMoveRun?.marketMoveRunRecapDetails,
    previewButtonState.isDisabled,
    isMetaDataValid,
    disableEditing,
    marketMoveMetaDataErrors,
  ]);

  useEffect(() => {
    setPreviewStale(previewButtonState.isDisabled);
  }, [previewButtonState.isDisabled]);


  const handlePreviewClick = () => {
    incrementPreviewDetailsFetchReq(true);
    setShowPreview(true);
    setRunStatus(RunStatus.DRAFT);
    setSaveOnRecap(true);
  };

  const getButtonText = () =>
    isPreviewStale || showPreview ? "Refresh Preview" : "Preview";

  const getRecapButtonText = () => {
    if (disableEditing || marketMoveRun?.marketMoveRun.runStatus === RunStatus.INPROGRESS) 
      return "View Details";
    if (!saveOnRecap && marketMoveRun?.marketMoveRun?.runStatus === RunStatus.SIGNEDOFF && !isMetaDataDirty) 
      return "Recap";
    return "Recap & Submit";
  };

  const onRecapClicked = () => {
    toggleRecap();
    if (!disableEditing && marketMoveRun?.marketMoveRun.runStatus !== RunStatus.INPROGRESS) {
      const shouldSaveRun = saveOnRecap || isMetaDataDirty;
      const status = shouldSaveRun ? RunStatus.DRAFT : undefined;
      incrementMMMetaDataSaveReq(status, shouldSaveRun);
    }
  };

  const handleAddNew = () => {
    const mm = generateDefaultMarketMove();
    mm.marketMoveId = "";
    reset(mm);
    setSelectedMarketMoveId("");
  };



  useEffect(() => {
    if (
      !hasInitialLoadCompleted &&
      !disableEditing &&
      listViewSelectedStores.length
    ) {
      handleAddNew();
      setHasInitialLoadCompleted(true);
    }
  }, [hasInitialLoadCompleted, disableEditing, listViewSelectedStores]);

  if (currentMarketMove?.marketMoveId === null) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        minHeight="100%"
      >
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          size="large"
          onClick={handleAddNew}
        >
          Add New Market Move
        </Button>
      </Box>
    );
  }

  return (
    <form onSubmit={() => {}}>
      <Grid container spacing={0.5} border={0} borderRadius={2}>
        <MarketMoveSettings />
        <ProductSettingsContainer />
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Tooltip title={previewButtonState.tooltipText}>
                <span>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={previewButtonState.isDisabled}
                    onClick={handlePreviewClick}
                    size="small"
                  >
                    {getButtonText()}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={9}>
              <Tooltip
                title={
                  recapButtonState.isDisabled
                    ? recapButtonState.tooltipText
                    : ""
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={onRecapClicked}
                    fullWidth
                    disabled={recapButtonState.isDisabled}
                    startIcon={
                      recapButtonState.isDisabled ? <ErrorOutlineIcon /> : null
                    }
                  >
                    {getRecapButtonText()}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default MarketMoveForm;
