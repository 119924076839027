import React, { useState, useEffect } from "react";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useMarketMoveMetaDataMethods } from "../../store/formStore";
import useMarketMoveStore from "../../store/marketMoveStore";

// Load timezone and UTC plugins for dayjs
dayjs.extend(utc);
dayjs.extend(timezone);

const CST_TIME_ZONE = "America/Chicago"; // CST timezone
const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:00";

const ZonedDateTimePicker = ({ name }: { name: "startDate" | "endDate" }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [minDateTime, setMinDateTime] = useState(dayjs()); // Default to now
  const { setValue, watch, formState: {errors, defaultValues} } = useMarketMoveMetaDataMethods();
  const { disableEditing } = useMarketMoveStore();
  const detectTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const formDate = watch(name);
  const formMMId = watch("marketMoveId");

  const valueDayjs = dayjs(formDate).tz(CST_TIME_ZONE);
  const now = dayjs();
  const error = errors[name];

  const setDateTimeBasedOnTimeZone = (valueDayjs: dayjs.Dayjs, now: dayjs.Dayjs) => {
    const userTimeZone = detectTimeZone();

    if (userTimeZone !== CST_TIME_ZONE) {
      const cstDate = dayjs.tz(valueDayjs, CST_TIME_ZONE);
      setSelectedDate(cstDate);
      setMinDateTime(now.tz(CST_TIME_ZONE));
    } else {
      console.debug("[setDateTimeBasedOnTimeZone] - User is in CST timezone");
      setSelectedDate(valueDayjs);
      setMinDateTime(now);
    }
  };

  // Set the default date-time to CST if the user's time zone is different
  useEffect(() => {
    setDateTimeBasedOnTimeZone(valueDayjs, now);
  }, [watch]);

  useEffect(() => {
    if (formMMId) {
      setSelectedDate(dayjs(formDate));
    } else {
      setSelectedDate(dayjs(defaultValues[name]));
    }

  }, [formMMId]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label="Select Date and Time (CST)"
        value={selectedDate}
        ampm={false}
        onChange={(newValue: dayjs.Dayjs) => {
          console.debug(`[ZonedDateTimePicker] - New value: ${newValue}`);
          console.debug(`[ZonedDateTimePicker] - Formatted value: ${newValue.format(DATE_TIME_FORMAT)}`);
          setValue(name, newValue.format(DATE_TIME_FORMAT), {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
        minDateTime={minDateTime} // Disable past dates and times
        slotProps={{
          textField: {
            fullWidth: true,
            error: !!error,
            helperText: error?.message,
          },
        }}
        disabled={disableEditing}
      />
    </LocalizationProvider>
  );
};

export default ZonedDateTimePicker;
