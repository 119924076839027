import { useEffect, useMemo, useState, useRef } from "react";
import { useWatch } from "react-hook-form";
import { useGetPreviewDetails } from "./previewDetailsApiHooks";
import {
  MarketMoveMetaDataSchema,
  PreviewDetailsSchema,
  MarketMoveRunDetailSchema,
  MarketMoveRunSchema,
  StoreProductConfigSchema,
} from "../schema/schemas";
import { useFormStore } from "../store/formStore";
import useGeneralStore from "../store/generalStore";
import useMarketMoveStore from "../store/marketMoveStore";
import { generatePreviewTableData } from "../component/previewTable/previewTableDataUtils";
import { enqueueSnackbar } from "notistack";
import { AxiosError } from "axios";
import { RunStatus } from "../interfaces/enums";
import { useFormActions } from "../store/formStore";
import { useGetSingleMMRun } from "./marketMoveRunApiHooks";

function transformData(
  data: MarketMoveRunDetailSchema[]
): MarketMoveRunDetailSchema[] {
  return data.map((item) => ({
    ...item,
    exclusionReason: item.exclusionReason ?? "",
  }));
}

function createDataMap(data: MarketMoveRunDetailSchema[]) {
  const dataMap = new Map<string, MarketMoveRunDetailSchema>();
  data.forEach((item) => {
    const key = `${item.storeId}-${item.productId}`;
    dataMap.set(key, item);
  });
  return dataMap;
}

function mergeRecapData(
  existingMarketMoveRecapDetails: MarketMoveRunDetailSchema[],
  previewDetails: MarketMoveRunDetailSchema[]
) {
  const existingDataMap = createDataMap(existingMarketMoveRecapDetails);

  return previewDetails.map((newItem) => {
    const key = `${newItem.storeId}-${newItem.productId}`;
    const existingItem = existingDataMap.get(key);

    return {
      ...newItem,
      mmRunRecapId: existingItem?.mmRunRecapId ?? newItem.mmRunRecapId,
      marketMoveId: existingItem?.marketMoveId ?? newItem.marketMoveId,
    };
  });
}

export const usePreviewData = () => {
  const { elasticData, showRecap, showPastMarketMoves } = useGeneralStore();
  const { previewDetailsFetchReq, setPreviewStale, disableEditing } =
    useMarketMoveStore();
  const [localRecapData, setLocalRecapData] = useState(null);

  const {
    previewDetailsMethods: {
      reset: resetPreviewDetails,
      control: previewDetailsControl,
      setValue: setPreviewDetailsValue,
      formState: { dirtyFields: previewDetailsDirtyFields },
    },
    marketMoveMetaDataMethods: { control: metaDataControl },
    marketMoveRunMethods: {
      control: marketMoveRunControl,
      setValue: setMarketMoveRunValue,
      watch: getMarketMoveRunValue,
    },
  } = useFormStore();

  const selectedMarketMove = useWatch<MarketMoveMetaDataSchema>({
    control: metaDataControl,
  });
  const previewDetails = useWatch<PreviewDetailsSchema>({
    control: previewDetailsControl,
  });
  const marketMoveRun = useWatch<MarketMoveRunSchema>({
    control: marketMoveRunControl,
  });
  const { isFetching: isMMRunFetching } = useGetSingleMMRun(
    selectedMarketMove?.marketMoveRunId
  );

  console.debug("usePreviewData - previewDetails", previewDetails);
  const disabled = disableEditing || !previewDetailsFetchReq.shouldFetch;
  const {
    data: recapData,
    refetch: refetchRecapData,
    ...rest
  } = useGetPreviewDetails(previewDetails, {
    enabled: false,
    onSuccess: (data: MarketMoveRunDetailSchema[]) => {
      setPreviewStale(false);
      const existingMarketMoveRunData =
        getMarketMoveRunValue("marketMoveRunRecapDetails") || [];
      console.debug(
        "usePreviewData - existingMarketMoveRunData",
        existingMarketMoveRunData
      );
      const transformedData = transformData(data);
      console.debug("usePreviewData - transformedData", transformedData);
      const mergedData = mergeRecapData(
        existingMarketMoveRunData,
        transformedData
      );
      console.debug("usePreviewData - mergedData", mergedData);

      setLocalRecapData(mergedData);
      setMarketMoveRunValue("marketMoveRunRecapDetails", mergedData);
      setPreviewDetailsValue("storeProductConfig", []);
      resetPreviewDetails(previewDetails);
      enqueueSnackbar("Preview data fetched successfully", {
        variant: "success",
      });
    },
    onError: (error: AxiosError) => {
      console.error("usePreviewData - Error fetching preview data", error);
      enqueueSnackbar(`Error fetching preview data: ${error.message}`, {
        variant: "error",
      });
    },
  });

  // useEffect(() => {
  //   refetchRecapData();
  // }, []);

  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    console.debug("usePreviewData - previewDetailsFetchReq effect triggered", {
      previewDetailsFetchReq,
    });
    if (previewDetailsFetchReq.shouldFetch) {
      console.debug("usePreviewData - Updating preview details", {
        storeList: selectedMarketMove?.storeList,
        productConfigs: marketMoveRun?.marketMoveRunProductConfigs,
      });
      setPreviewDetailsValue(
        "marketMoveStores",
        selectedMarketMove?.storeList.filter((s) => s.includeFlag),
        {
          shouldDirty: true,
        }
      );
      setPreviewDetailsValue(
        "marketMoveProductConfigs",
        selectedMarketMove?.productConfigList,
        { shouldDirty: true }
      );
      console.debug(
        "usePreviewData - selectedMarketMove?.productConfigList",
        previewDetails.marketMoveProductConfigs
      );
      console.debug("usePreviewData - Updating store product configs", {
        recapDetails: marketMoveRun?.marketMoveRunRecapDetails,
      });
      updateStoreProductConfigs(marketMoveRun?.marketMoveRunRecapDetails);
      setIsReadyToFetch(true);
    }
  }, [previewDetailsFetchReq.counter]);

  useEffect(() => {
    if (!isInitialMount.current && isReadyToFetch) {
      console.debug("usePreviewData - Triggering refetch");
      refetchRecapData({
        queryKey: ["previewDetails", previewDetails],
      });
      setIsReadyToFetch(false);
    } else {
      isInitialMount.current = false;
    }
  }, [isReadyToFetch, previewDetails]);

  useEffect(() => {
    setLocalRecapData(null);
    setPreviewStale(true);
    console.debug(
      "usePreviewData - Reset local states. marketMoveId:",
      selectedMarketMove?.marketMoveId,
      "marketMoveRunId:",
      marketMoveRun?.marketMoveRun?.marketMoveRunId
    );
  }, [selectedMarketMove?.marketMoveId]);

  const { updateStoreProductConfig, getStoreProductConfig } = useFormActions();

  const updateStoreProductConfigs = (
    recapDetails: MarketMoveRunDetailSchema[]
  ) => {
    recapDetails.forEach((detail) => {
      const existingConfig = getStoreProductConfig(
        detail.storeId,
        detail.productId
      );

      const shouldConsiderNewConfig =
        detail.manualPriceOverrideFlag ||
        (detail.includeFlag === false &&
          !detail.exclusionReason &&
          selectedMarketMove?.storeList?.filter(
            (s) => s.storeId === detail.storeId
          )[0]?.includeFlag);

      if (shouldConsiderNewConfig) {
        const newConfig: Partial<StoreProductConfigSchema> = {
          storeId: detail.storeId,
          productId: detail.productId,
          includeFlag: detail.includeFlag,
          manualPriceOverrideFlag: detail.manualPriceOverrideFlag,
          manualPrice: detail.manualPrice || 0,
        };

        const mergedConfig = { ...newConfig, ...existingConfig };

        if (
          !existingConfig ||
          JSON.stringify(existingConfig) !== JSON.stringify(mergedConfig)
        ) {
          console.debug(
            "usePreviewData - Updating store product config",
            detail.storeId,
            detail.productId,
            mergedConfig
          );
          updateStoreProductConfig(
            detail.storeId,
            detail.productId,
            mergedConfig
          );
        }
      }
    });
  };

  const recapDataToUse = useMemo(() => {
    console.debug(
      "usePreviewData - recapDataToUse",
      localRecapData,
      marketMoveRun?.marketMoveRunRecapDetails
    );
    return localRecapData?.length > 0
      ? localRecapData
      : marketMoveRun?.marketMoveRunRecapDetails;
  }, [localRecapData, marketMoveRun?.marketMoveRunRecapDetails]);

  const memoizedTableData = useMemo(() => {
    console.debug("usePreviewData - memoizedTableData", recapDataToUse);

    if (
      showPastMarketMoves ||
      (showRecap &&
        marketMoveRun?.marketMoveRun?.runStatus === RunStatus.INPROGRESS)
    ) {
      console.debug("usePreviewData - Using runDetails due to INFLIGHT status");
      return generatePreviewTableData(
        elasticData,
        marketMoveRun.marketMoveRunDetails
      );
    }

    return generatePreviewTableData(elasticData, recapDataToUse);
  }, [
    elasticData,
    recapDataToUse,
    marketMoveRun?.marketMoveRun?.runStatus,
    marketMoveRun?.marketMoveRunDetails,
  ]);

  return {
    recapData: recapDataToUse,
    tableData: memoizedTableData,
    selectedMarketMove,
    callState: {
      shouldFetchPreview: previewDetailsFetchReq.shouldFetch,
      ...rest,
      isLoading: rest.isFetching || isMMRunFetching,
    },
  };
};
